<script setup lang="ts">
const route = useRoute();
</script>

<template>
  <div
    class="flex flex-grow"
    :class="{
      'with-bg-1': route.meta?.background === 1,
      'with-bg-2': route.meta?.background === 2,
      'with-bg-3': route.meta?.background === 3,
    }"
  >
    <NuxtPage />
  </div>
</template>

<style lang="scss" scoped>
.with-bg-1 {
  @apply bg-right-bottom bg-no-repeat;
  background-image: url(/images/backgrounds/background-1.svg);
}
.with-bg-2 {
  background-image: url(/images/backgrounds/background-2.svg);
  background-repeat: no-repeat;
  background-position: bottom left;
}
.with-bg-3 {
  background-image: url(/images/backgrounds/background-3.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
</style>
